<template>
    <div class="team">
        <div class="banner">
            <img src="@/assets/team-banner.png" class="banner-img"/>
            <div class="banner-text">
                <img src="@/assets/team-text.png" />
            </div>
            <div class="note">Our team consists of industry experts and seasoned professionals with deep backgrounds in supply chain management, artificial intelligence, and data analysis.</div>
        </div>

        <div class="info">
            <div class="item">
                <img src="@/assets/team-1.png" />
                <div class="item-message">
                    <div class="title">Oliver Smith</div>
                    <div class="mark">As the CEO, Oliver leverages extensive expertise in supply chain management and technology innovation, leading transformational projects in multinational corporations to enhance supply chain performance.</div>
                </div>
            </div>
            <div class="item">
                <div class="item-message">
                    <div class="title">Felix Planck</div>
                    <div class="mark">As the Chief Technology Officer, Emma specializes in AI and machine learning, overseeing the development of cutting-edge predictive models that drive operational efficiency and foster continuous technological improvements.</div>
                </div>
                <img src="@/assets/team-2.png" />
            </div>
            <div class="item">
                <img src="@/assets/team-3.png" />
                <div class="item-message">
                    <div class="title">Noah Williams</div>
                    <div class="mark">Serving as the Operations Director, Noah has significant experience in logistics and operations management, focusing on streamlining processes and maximizing customer satisfaction through effective supply chain strategies.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.banner {
    position: relative;

    .banner-text {
        position: absolute;
        top: 190px;
        left: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 604px;
        height: 68px;
        transform: translate(-50%, 0);

        > img {
            width: 100%;
        }
    }

    .note {
        position: absolute;
        top: 280px;
        left: 50%;
        width: 677px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        text-align: center;
        transform: translate(-50%, 0);
    }
}

.info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 1354px;
    padding-top: 85px;
    background-color: #FFFFFF;
    z-index: 10;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../assets/team-bg.png") no-repeat;
        z-index: -1;
    }

    .item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 864px;
        margin-bottom: 90px;
        gap: 77px;

        > img {
            width: 288px;
            height: 330px;
        }

        .item-message {
            padding-top: 66px;

            .title {
                font-size: 24px;
                color: #050505;
                line-height: 20px;
                margin-bottom: 24px;
            }

            .mark {
                font-size: 14px;
                color: #050505;
                line-height: 20px;
            }
        }
    }
}
</style>